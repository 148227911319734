<template>
  <app-input-box
    :errors="cmpErrors"
    :clean="clean"
    :is-cleared="isCleared"
    :helper-text="helperText"
    :helper-text-type="helperTextType"
  >
    <input
      :name="name"
      :class="{
        filled: filled,
        'without-label': !label,
        'has-additional': hasAdditional,
        'has-loader': displayLoader,
        hover: inputHoverStyle
      }"
      v-bind="$attrs"
      ref="input"
      v-model="cmpValue"
      @input="update"
      @focus="focus"
      @click="cleanErrors"
      @blur="getBlur(clean, $event)"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keyup', $event)"
    >

    <label
      v-if="label"
      @click="clickByLabel"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
    >
      {{ label }}
    </label>

    <div class="loader-wrapper" v-if="withLoader">
      <app-loader :react-to-ajax="withLoader" @running="onRunning" />
    </div>

    <div
      class="btn-additional"
      v-if="hasAdditional"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
    >
      <app-tooltip :text="tooltip" v-if="tooltip" />
      <slot name="additional" v-bind="self" />
    </div>
  </app-input-box>
</template>

<script>
  import inputMixin from '../../mixins/inputMixin'

  export default {
    mixins: [inputMixin],

    emits: ['update:modelValue', 'focus', 'keyup'],

    data () {
      return {
        displayLoader: false
      }
    },

    computed: {
      self () {
        return this
      },

      filled () {
        return !(this.cmpValue === undefined || this.cmpValue === null || this.cmpValue === '')
      },

      hasAdditional () {
        return (!!this.$slots.additional || this.tooltip) && !this.displayLoader
      }
    },

    watch: {
      modelValue (newValue) {
        if (newValue !== this.cmpValue) {
          this.cmpValue = newValue
          this.cleanErrors()
        }
      }
    },

    methods: {
      update () {
        this.$emit('update:modelValue', this.cmpValue)
      },

      focus () {
        this.$emit('focus')
      },

      clean () {
        this.cmpValue = null
        this.cleanErrors()
        this.update()
      },

      focused () {
        this.$refs.input.focus()
      },

      getInput () {
        return this.$refs.input
      },

      onRunning (data) {
        this.displayLoader = data
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "/app/javascript/assets/styles/components/input.scss";

  .input-box {
    &[type="password"] input {
      padding: 27px 40px 9px 12px;
    }

    &.bg-transparent input {
      background-color: transparent !important;
    }
  }
</style>
