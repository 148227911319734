export default {
  beforeMount (el, { value }, vnode) {
    if (!value) return

    const input = el.querySelector('input') || el
    const button = document.createElement('span')

    button.classList.add('btn-additional')

    const i = document.createElement('i')
    i.classList.add('material-icons')
    i.innerHTML = 'content_copy'

    button.appendChild(i)
    el.appendChild(button)
    el.classList.add('has-additional')
    button.addEventListener('click', () => {
      input.focus();
      input.select();

      try {
        const successful = document.execCommand('copy');
        const div = document.createElement('div')
        div.innerHTML = '<i class="material-icons">check</i> <span>Скопировано</span>';
        div.classList.add('copied-tooltip', 'animate__animated', 'animate__bounceOut')
        el.appendChild(div);
        div.addEventListener('animationend', () => {
          div.remove();
        });
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    })
  }
}
